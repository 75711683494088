// components/auth/ProtectedPage.js
import React, { useMemo } from "react";
import { useUser } from "@auth0/nextjs-auth0/client";
import Link from "next/link";
import LoadingState from "@/components/feedback/LoadingState";
import LoginButton from "@/components/auth/LoginButton";

const ProtectedPage = React.memo(
	({
		children,
		role = null,
		title = "Sign In Required",
		description = "You need to be logged in to view this page.",
		showHomeLink = false,
	}) => {
		const { user, isLoading } = useUser();
		const namespace = process.env.NEXT_PUBLIC_AUTH0_NAMESPACE;

		const hasRequiredRole = useMemo(() => {
			if (!role || !user) return false;
			return user[namespace]?.includes(role);
		}, [user, namespace, role]);

		const isAuthorized = role ? user && hasRequiredRole : !!user;

		if (isLoading) {
			return <LoadingState />;
		}

		if (!isAuthorized) {
			return (
				<main className="px-4 sm:px-6 py-4">
					<div className="bg-white">
						<h2 className="text-xl font-semibold mb-4">{title}</h2>
						<p className="mb-6">{description}</p>

						{showHomeLink && user && (
							<Link
								href="/"
								className="inline-block px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 font-semibold"
							>
								Home
							</Link>
						)}

						{!user && <LoginButton />}
					</div>
				</main>
			);
		}

		return <>{children}</>;
	}
);

ProtectedPage.displayName = "ProtectedPage";
export default ProtectedPage;
