// src/hooks/useSearch.js
import { useState, useCallback, useMemo } from "react";

export const useSearch = ({ data, searchProperty = "name", initialSearchTerm = "" }) => {
	const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

	const handleSearch = useCallback((value) => {
		setSearchTerm(value);
	}, []);

	const clearSearch = useCallback(() => {
		setSearchTerm("");
	}, []);

	const filteredData = useMemo(() => {
		if (!data) return null;
		if (!searchTerm) return data;

		const lowercaseSearchTerm = searchTerm.toLowerCase().trim();
		const terms = lowercaseSearchTerm.split(/\s+/);

		return data.filter((item) => {
			const value = String(item[searchProperty]).toLowerCase();
			const words = value.split(/\s+/);
			return terms.every((term) => words.some((word) => word.startsWith(term)));
		});
	}, [data, searchTerm, searchProperty]);

	return {
		searchTerm,
		handleSearch,
		clearSearch,
		filteredData,
	};
};
