import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

const SearchInput = React.memo(
	({
		searchTerm,
		onSearch,
		onClear,
		placeholder = "Search",
		containerClassName = "w-full md:w-1/2",
		showIcon = true,
	}) => (
		<div className={`relative mb-0 ${containerClassName}`}>
			<div className="relative flex items-center">
				{showIcon && <MagnifyingGlassIcon className="absolute left-3 h-5 w-5 text-gray-400" />}
				<input
					type="text"
					placeholder={placeholder}
					value={searchTerm}
					onChange={(e) => onSearch(e.target.value)}
					className={`w-full p-2 border rounded ${showIcon ? "pl-10" : "pl-3"} pr-10`}
				/>
				{searchTerm && (
					<button
						onClick={onClear}
						className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-500 hover:text-gray-700"
						aria-label="Clear search"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							className="w-6 h-6"
						>
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</button>
				)}
			</div>
		</div>
	)
);

SearchInput.displayName = "SearchInput";

export default SearchInput;
