// components/auth/ProtectedHomepage.js
import ProtectedPage from "./ProtectedPage";

export default function ProtectedHomepage({ children }) {
	return (
		<ProtectedPage title="Welcome to the Rent IQ App" description="Please sign in below">
			{children}
		</ProtectedPage>
	);
}
