// components/AuthPageHead.js
import React from "react";
import Head from "next/head";

// Can pass a title in if I want <AuthPageHead title="Your Profile | Rent IQ App" />
// But it has the proper default title
const AuthPageHead = React.memo(({ title = "Rent IQ" }) => {
	return (
		<Head>
			<title>{title}</title>
			<meta name="robots" content="noindex, nofollow" />
		</Head>
	);
});

AuthPageHead.displayName = "AuthPageHead";
export default AuthPageHead;
