// src/pages/index.js
import React from "react";
import PropertiesContent from "@/components/data-display/PropertiesContent";
import ProtectedHomepage from "@/components/auth/ProtectedHomepage";

const HomePage = () => {
	return (
		<ProtectedHomepage>
			<PropertiesContent />
		</ProtectedHomepage>
	);
};

HomePage.displayName = "HomePage";

export default HomePage;
