//src/components/data-display/PropertiesContent.js
import React from "react";
import { useUser } from "@auth0/nextjs-auth0/client";
import dynamic from "next/dynamic";
import { useAccessTokenQuery } from "@/hooks/useAccessTokenQuery";
import { useGetProperties } from "@/hooks/properties/useGetProperties";
import { useSearch } from "@/hooks/useSearch";
// Components
import ErrorMessage from "@/components/feedback/ErrorMessage";
import AuthPageHead from "@/components/seo/AuthPageHead";
import SearchInput from "@/components/inputs/SearchInput";
import PropertiesContentSkeleton from "@/components/feedback/loading-skeletons/PropertiesContentSkeleton";

// Dynamically import PropertyList
const PropertyList = dynamic(() => import("@/components/data-display/PropertyList"), {
	ssr: false,
});

const PropertiesContent = () => {
	const { user } = useUser();
	const auth0UserId = user?.sub;

	const { data: accessToken, isError: isErrorAccessToken, isLoading: isLoadingAccessToken } = useAccessTokenQuery();

	const {
		data: properties,
		isLoading: isLoadingProperties,
		isError: isErrorProperties,
	} = useGetProperties({ accessToken, auth0UserId });

	const {
		searchTerm,
		handleSearch,
		clearSearch,
		filteredData: filteredProperties,
	} = useSearch({
		data: properties,
		searchProperty: "name",
	});

	const isLoading = isLoadingAccessToken || isLoadingProperties;

	if (isLoading) return <PropertiesContentSkeleton />;
	if (isErrorAccessToken || isErrorProperties) return <ErrorMessage />;

	return (
		<>
			<AuthPageHead />
			<div className="p-4 pt-8 md:p-8 bg-off-white min-h-screen">
				<h1 className="text-2xl font-semibold mb-4">Properties</h1>
				<SearchInput
					searchTerm={searchTerm}
					onSearch={handleSearch}
					onClear={clearSearch}
					placeholder="Search by property"
				/>
				<PropertyList filteredProperties={filteredProperties} isLoading={isLoading} />
			</div>
		</>
	);
};

export default PropertiesContent;
