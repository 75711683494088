// components/auth/LoginButton.js
import Link from "next/link";

const LoginButton = () => {
	return (
		<Link
			href="/api/auth/login"
			className="inline-block px-4 py-2 no-underline bg-purple text-white rounded hover:bg-purple-darker transition duration-300"
		>
			Log In
		</Link>
	);
};

export default LoginButton;
